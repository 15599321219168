import { getAuth } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  getDoc,
  where 
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase";
import ListingHead from "../components/ListingHead";
import Test1 from "../components/Test1";

const sectionCountsObj = {
  "Solo": { count: 1, value: 140 },
  "Duo": { count: 2, value: 100 },
  "Pas de deux": { count: 3, value: 100 },
  "Trio": { count: 4, value: 100 },
  "Quartet": { count: 5, value: 100 },
  "Grup 5 - 11 dansatori": { count: 6, value: 90 },
  "Formație 12+ dansatori": { count: 7, value: 90 },
};

export default function Profile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const auth = getAuth();
  const navigate = useNavigate();
  const [listings, setListings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    telefon: "",
  });
  const { name, email, telefon } = formData;

  useEffect(() => {
    const fetchUserListings = async () => {
      try {
        if (auth.currentUser) {
          const userDocRef = doc(db, "users", auth.currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setFormData((formData) => ({
              ...formData,
              name: userData.name,
              telefon: userData.telefon,
              email: userData.email,
            }));
          }

          const listingRef = collection(db, "listings");
          const q = query(
            listingRef,
            where("userRef", "==", auth.currentUser.uid),
          );
          const querySnap = await getDocs(q);
          let listings = [];
          querySnap.forEach((doc) => {
            listings.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          
          const sortedListings = listings.sort((a, b) => {
            const sectiuneA = a.data.sectiune;
            const sectiuneB = b.data.sectiune;
            return (
              sectionCountsObj[sectiuneA].count - sectionCountsObj[sectiuneB].count
            );
          });
          setListings(sortedListings);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    fetchUserListings();
  }, [auth.currentUser]);

  const onLogout = () => {
    auth.signOut();
    navigate("/");
  };

  const onDelete = async (listingID) => {
    if (window.confirm("Sigur vrei sa stergi?")) {
      await deleteDoc(doc(db, "listings", listingID));
      const updatedListings = listings.filter(
        (listing) => listing.id !== listingID
      );
      setListings(updatedListings);
      toast.success("Sters cu succes");
    }
  };

  const onEdit = (listingID) => {
    navigate(`/edit/${listingID}`);
  };

  const displaySectiune = (listing) => {
    let sectionValue = sectionCountsObj[listing.data.sectiune]?.value || 0;
    return sectionValue;
  };
  
  const total = listings ? listings.reduce((acc, listing) => {
    return acc + (displaySectiune(listing) * listing.data.participanti);
  }, 0) : 0;

  const participanti = listings ? listings.reduce((acc, listing) => {
    return acc + parseInt(listing.data.participanti);
  }, 0) : 0;
  return (
    <div>
    <div className="max-w-7xl hidden md:block mt-10 px-3 mx-auto">
            <ListingHead />
      {!loading && listings && listings.length > 0 && (
        <>
          <div>
            {listings.map((listing) => (
              <Test1
                key={listing.id}
                id={listing.id}
                listing={listing.data}
                onDelete={() => onDelete(listing.id)}
                onEdit={() => onEdit(listing.id)}
              />
            ))}
          </div>
        </>
      )}
    </div>
    </div>
  );
}
