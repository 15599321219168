import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  function onChange(e) {
    setEmail(e.target.value);
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      toast.success("Email a fost trimis");
    } catch (error) {
      toast.error("Nu se trimite");
    }
  }
  return (
    <section>
      <h1 className="text-2xl text-center mt-6 font-bold">Resetare Parola</h1>
      <div className="flex justify-center flex-wrap items-center px-6 py-12 max-w-6xl mx-auto">
      
        <div className="w-full md:w-[67%] lg:w-[40%] lg:ml-20">
          <form onSubmit={onSubmit}>
            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              placeholder="Email "
              className="input w-full transition duration-150 ease-in-out cursor-pointer"
              />

<div className="flex justify-between whitespace-nowrap">
              <p className="mb-6 mt-6">
                <Link
                  to="/sign-up"
                  className="ml-1"
                >
                  Creaza Cont
                </Link>
              </p>
              <p className="mb-6 mt-6">
                <Link
                  to="/sign-in"
                  className="mr-1"
                >
                  Sign in
                </Link>
              </p>
            </div>
          <p className='divider'/>
            <button
                className="w-full transition duration-150 ease-in-out"
                type="submit"
            >
              Reseteaza Parola
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
