import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTheme } from './components/ThemeContext';
import Home from "./pages/Home";
import Regulament from "./pages/Regulament";
import Jurizare from "./pages/Jurizare";
import Premii from "./pages/Premii";
import Taxa from "./pages/Taxa";
import InscrieriTemp from "./pages/InscrieriTemp";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateListing from "./pages/CreateListing";
import EditListing from "./pages/EditListing";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Top from "./components/Top";
import Top2 from "./components/Top2";
import Test from "./pages/Test";

export default function App({ initialTheme }) { 
  const { theme } = useTheme();
 
  return (
    <>
    <div className="theme page page-container" data-theme={theme}>
      <Router>
        <Top />
        <Navbar />
        <Routes>
          <Route path="/test" element={<Test />} />
          <Route path="/" element={<Home />} />
          <Route path="/regulament" element={<Regulament />} />
          <Route path="/jurizare" element={<Jurizare />} />
          <Route path="/premii" element={<Premii />} />
          <Route path="/taxa" element={<Taxa />} />
          <Route path="/inscrieri-temp" element={<InscrieriTemp />} />
          <Route path="/profile" element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/inscrieri" element={<PrivateRoute />}>
            <Route path="/inscrieri" element={<CreateListing />} />
          </Route>
          <Route path="edit" element={<PrivateRoute />}>
            <Route path="/edit/:listingId" element={<EditListing />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Footer />
      </div>
    </>
  );
}

