import React from "react";
import Logo from "../assets/Logo.png";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { useState, useEffect } from 'react';

const Top = () => {
    
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize); 

    return () => {
      window.removeEventListener('resize', handleResize); 
    };
  }, []);
  return (
    <footer className="max-w-6xl mx-auto">
      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3
      text-center"
      >

<div className="flex flex-col items-center">
  {!isMobile && (
    <div className="flex items-center">
      <a href="https://www.facebook.com/people/SIA-Dance-Cup/61558774046614/" target="_blank" className="mr-1 flex items-center">
        <FaFacebook size={21} style={{ color: '#5179cf', marginRight: '5px' }} />
        @sia-dance-cup
      </a>
    </div>
  )}
</div>


<div className="top flex flex-col items-center">
   <p className="text-[16px] font-semibold text-center mt-5 mb-2">#NeverMissAChanceToDance</p>
</div>

<div className="flex flex-col items-center">
      {!isMobile && ( // Ascunde elementul doa
        <div className=" flex items-center">
    <a href="https://www.instagram.com/siadancecup" target="_blank" className="mr-1 flex items-center">
    <FaInstagram size={22} style={{ color: '#c7265c', marginLeft: '5px' }} />
&nbsp;@siadancecup
</a>
</div>
      )}
    </div>

      </div>
    </footer>
  );
};

export default Top;