import { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { toast } from "react-toastify";

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;
  const navigate = useNavigate();
  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }
  async function onSubmit(e) {
    e.preventDefault();
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        navigate("/profile");
      }
    } catch (error) {
      toast.error("Bad user credentials");
    }
  }
  return (
    <section>
      <h1 className="text-xl text-center mt-16 font-bold">Sign In</h1>
      <div className="flex justify-center flex-wrap items-center px-6 py-12 max-w-6xl mx-auto">
       
    
        <div className="w-full md:w-[67%] lg:w-[40%] lg:ml-2">
          <form onSubmit={onSubmit}>
            <div className="relative">
          <p className="text-lg font-semibold">Email</p>
            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              placeholder="...@"
              className="input w-full transition duration-150 ease-in-out cursor-pointer"
               />
            </div>
        <div className="w-full mb-6 mt-2 relative">
          <p className="text-lg font-semibold">Parola</p>
          <input 
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={onChange}
                placeholder=". . . . "
           className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer">
         </input>
              {showPassword ? (
          <AiFillEyeInvisible 
          onClick={() => setShowPassword((prevState) => !prevState)}
          className="absolute right-0 top-0 mt-10 mr-4 text-xl cursor-pointer" />
          ) : (
          <AiFillEye 
          onClick={() => setShowPassword((prevState) => !prevState)}
          className="absolute right-0 top-0 mt-10 mr-4 text-xl cursor-pointer" />
          )}
        </div>
            <div className="flex justify-between whitespace-nowrap">
              <p className="mb-6">
                <Link
                  to="/sign-up"
                  className="ml-1"
                >
                  Creaza Cont
                </Link>
              </p>
              <p>
                <Link
                  to="/forgot-password"
                >
                  Reseteaza parola
                </Link>
              </p>
            </div>
          <p className='divider'/>
            <button
              type="submit"
              className="w-full transition duration-150 ease-in-out"
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
