import React, { useState } from "react";
import './table.css'; 
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FaFileAudio } from "react-icons/fa";
import { FaPlay, FaPause, FaDownload } from "react-icons/fa";

export default function ListingItem({ listing, id, onEdit, onDelete }) {
  
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };
  
  const displaySectiune = () => {
    if (listing.sectiune === "Formație 12+ dansatori") {
      return "12+ dansatori";
    } else if (listing.sectiune === "Grup 5 - 11 dansatori") {
      return "5-11 dansatori";
    }
    return listing.sectiune;
  };
  
  const displayVarsta = () => {
    if (listing.varsta === "Juniors Maxi 13 - 15 ani") {
      return "13 - 15 ani";
    } 
    return listing.varsta;
  };

  const sectionTax = {
    "Solo": { count: 1, value: 140 },
    "Duo": { count: 2, value: 100 },
    "Pas de deux": { count: 3, value: 100 },
    "Trio": { count: 4, value: 100 },
    "Quartet": { count: 5, value: 100 },
    "Grup 5 - 11 dansatori": { count: 6, value: 90 },
    "Formație 12+ dansatori": { count: 7, value: 90 },
  };

  const taxValue = sectionTax[listing.sectiune].value * listing.participanti;
 
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = React.createRef();

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = audioRef.current.currentTime;
    const duration = audioRef.current.duration;
    const calculatedProgress = (currentTime / duration) * 100;
    setProgress(calculatedProgress);
  };
 
  return (
    <div>
      <table className="table">
        <tbody>
          <tr>
            <td>{listing.concurent}</td>
            <td>{listing.disciplina}</td>
            <td>{displayVarsta()}</td>
            <td>{displaySectiune()}</td>
            <td className="td1">{listing.pregatire}</td>
            <td>{listing.moment}</td>
            <td>{listing.coregraf}</td>
            <td className="td2">{listing.participanti}</td>
            <td className="td2">{listing.durata}</td>
            <td className="td0">{listing.intrare}</td>
            <td className="td2">
  <div className="relative inline-flex  ml-1">{taxValue}
    </div>
    </td>
    <td className="td2">
        <div className="flex justify-center items-center">
  <FaFileAudio className="text-blue-400 cursor-pointer" size={18} onClick={openPopup} />
  {isPopupOpen && (
    <div className="popup">
      <div className="popup-content">
        <div className="close-button cursor-pointer" onClick={closePopup}>inchide   </div>
        {listing && (
          <div className="controls-container flex justify-center">
          <audio controls style={{ padding: '12px' }}>
              <source src={listing.imageUrls} type="audio/mpeg" />
            </audio>
          </div>
        )}
      </div>
    </div>
  )}
</div>
            </td>
            <td className="td2">
  <div className="relative inline-flex items-center ml-2 mt-2">
    <MdEdit
      className="myDIV inline-flex items-center cursor-pointer transition-colors duration-200 hover:text-blue-400"
      onClick={() => onEdit(listing.id)}
    />
    <div className="hide">Editeaza</div>
  </div>
  <div className="relative inline-flex items-center ml-3 mt-2">
    <FaTrash
      className="myDIV inline-flex items-center w-[12px] cursor-pointer transition-colors duration-200 hover:text-red-400"
      onClick={() => onDelete(listing.id)}
    />
    <div className="hide">Sterge</div>
  </div>
              </td>
          </tr>
        </tbody>
      </table>
    <p className='divider1' />
    </div>
  );
}
