import React, {useEffect } from "react";
import Grid from "@mui/material/Grid";

export default function Premii() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="max-w-6xl mx-auto mt-20">
    <p className="text-left text-[20px] font-semibold mx-6 mb-1">
    Premii SIA Dance Cup 9 Noiembrie 2024
    </p>
  <p className='divider'/>
    <div className="text-center mt-10">
  <p className="text-left text-[18px] font-semibold mx-6 mt-10">
  Fiecare participant va primi diplomă și trofeu personalizat pentru fiecare apariție scenică.
   </p>
  <p className="text-left text-[18px] font-semibold mx-6 mt-5">
  Fiecare scoala participanta va primi diploma si trofeu personalizat.
   </p>
  <p className="text-left text-[18px] font-semibold mx-6 mt-5">
  Premii speciale oferite de parteneri și colaboratori.
  </p>
  <p className="text-left text-[18px] font-semibold mx-6 mt-5 mb-10">
  Vouchere pentru participarea la viitoarle ediții SIA Dance Cup.
  </p>
</div>
  <p className='divider'/>
      
  <p className="text-left text-[18px] font-semibold mx-6 mt-5 mb-10">
  PREMII SPECIALE:
  </p>
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        BEST Female solo
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        BEST Male solo
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        BEST Duo
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        BEST Trio
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        BEST Quartet
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        BEST Group
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        BEST Formation
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        BEST Choreography
        </div>
      </Grid>
    </Grid>
    </div>
  );
};
