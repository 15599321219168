import React from 'react';
import './table.css'; 

export default function ListingHead({}) {
  return (
    <div>
      <table className="table mb-3">
        <thead>
          <tr>
            <th>Concurent</th>
            <th>Disciplina</th>
            <th>Varsta</th>
            <th>Sectiune</th>
            <th className="th1">Nivel</th>
            <th>Moment</th>
            <th>Coregraf</th>
            <th className="th2">Nr.D</th>
            <th className="th2">Timp</th>
            <th className="th0">Intrare</th>
            <th className="th2">Taxa</th>
            <th className="th2">Mp3</th>
            <th className="th2">E / S</th>
          </tr>
        </thead>
      </table>
    <p className='divider1' />
    </div>
  );
}
