import React, { useRef, useState } from "react";
import Logo2 from "../assets/Logo2.png";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {


  return (
    <footer className="max-w-6xl mx-auto">
    <p className='divider'/>
      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
      text-center pt-2"
      >

<div className="flex justify-center items-center flex-col">
 
      <form>
              <p className="mb-2">Contact </p>
              <div className="w-[270px]">
                  <input
                    className="input w-full transition duration-150 ease-in-out cursor-pointer"
                    placeholder="Email"
                  />
                </div>
                <div className="w-[270px]">
                  <textarea className="input w-full transition duration-150 ease-in-out cursor-pointer"
                  id="message" 
                  name="message" 
                  placeholder="Mesaj" required />
                </div>
        <button
          type="submit"
          className=" w-full bg-transparent text-gray-500 px-7 py-3 text-sm font-medium uppercase rounded-50 shadow-md hover:bg-purple-900 transition duration-150 ease-in-out hover:shadow-lg"
          >
          Trimite
        </button>
                </form>
</div>

       <div className="flex justify-center items-center flex-col">
  <div className="flex justify-center items-center">
    <img src={Logo2} width={120} alt="logo" className="mr-1" />
  </div>
  <div className="mt-3 mb-2 flex items-center">
  <a href="https://www.facebook.com/people/SIA-Dance-Cup/61558774046614/" target="_blank">
    <FaFacebook size={25} style={{ color: '#5179cf' }} />
</a>
    &nbsp;
    <a href="https://www.instagram.com/siadancecup" target="_blank">
    <FaInstagram size={25} style={{ color: '#c7265c', marginLeft: '5px' }} />
</a>
</div>
   <p className="text-[15px] text-gray-400 mt-1">#NeverMissAChanceToDance</p>
</div>

        <div className="flex flex-col items-center px-6 mt-12">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.958972425768!2d26.09987487528497!3d44.434003801583735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff1d30b93891%3A0x8aecb48c74b663d1!2sSala%20Luceaf%C4%83rul!5e0!3m2!1sen!2sro!4v1713803894578!5m2!1sen!2sro" 
    allowFullScreen=""
    loading="lazy"
    title="Google Maps"
  ></iframe>
  <div className="text-center text-[16px] text-gray-500 my-2">
    <div>Strada Ion Ghica nr.3</div>
    <div>București</div>
  </div>
</div>

        
      </div>
    <p className='divider'/>
      <div
       className="text-[15px] text-gray-500
      text-center pb-4"
      >
        © 2024 SIA Dance Cup
      </div>
    </footer>
  );
};

export default Footer;
