import React, {useEffect } from "react";
import Grid from "@mui/material/Grid";
import { FaRegUserCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Jurizare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="max-w-6xl mx-auto mt-20">
    <p className="text-left text-[20px] font-semibold mx-6 mb-1">
    Jurizare SIA Dance Cup 9 Noiembrie 2024
    </p>
  <p className='divider'/>
  <p className="text-left text-[18px] font-semibold mx-6 mt-10 mb-10">
  Membri juriului
   </p>
      
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={6} md={3}>
        <div className="StyledPoza">
    <FaRegUserCircle className="FaRegUserCircle" />
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledPoza">
    <FaRegUserCircle className="FaRegUserCircle" />
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledPoza">
    <FaRegUserCircle className="FaRegUserCircle" />
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledPoza">
    <FaRegUserCircle className="FaRegUserCircle" />
        </div>
      </Grid>
    </Grid>
  <p className="text-left text-[18px] font-semibold mx-6 mt-10 mb-10">
  Critreriile evaluate în cadrul competiției sunt următoarele:
   </p>
      
   <Grid container spacing={1} alignItems="center" justifyContent="center">
  <Grid item xs={12} sm={6} md={3} mx={2}>
    <div className="StyledCard1">
      Nivelul Tehnic / Execuție
    </div>
  </Grid>
  <Grid item xs={12} sm={6} md={3} mx={2}>
    <div className="StyledCard1">
      Interpretarea artistică
    </div>
  </Grid>
  <Grid item xs={12} sm={6} md={3} mx={2}>
    <div className="StyledCard1">
      Orientarea scenică
    </div>
  </Grid>

    </Grid>
    <div className="text-center mt-10">
 
<p className="text-left text-[18px] font-semibold mx-6 mt-10">
  ! Pentru mai multe detalii legate de jurizare vă rugăm să consultați <Link to="/regulament" className="text-[#a254bf] no-underline"> regulamentul</Link>
</p>
  <p className="text-left text-[18px] font-semibold mx-6 mt-5">
  !! Pentru secțiunea solo jurizarea se va face separat pentru fete și băieți, cât și pentru variațiile de balet cu sau fără pointe.
   </p>
  <p className="text-left text-[18px] font-semibold mx-6 mt-5 mb-10">
  !!! Hotărârile juruiului și punctajele sunt definitive și incontestabile.</p>
</div>
    </div>
  );
};
