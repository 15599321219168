// Navbar.jsx

import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useTheme } from './ThemeContext';
import Logo2 from "../assets/Logo2.png";
import { Toggle } from "./Toggle";
import { RxHamburgerMenu } from "react-icons/rx";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const [pageState, setPageState] = useState("Sign in");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState("Contul meu");
      } else {
        setPageState("Sign in");
      }
    });
  }, [auth]);

  function pathMatchRoute(route) {
    return route === location.pathname;
  }

  function handleLinkClick(path) {
    navigate(path);
    setIsMenuOpen(false);
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); 
  };
 
  return (
    <nav className="title">
      <div className="flex items-center">
        <Link to="/" className="title">
          <div className="flex items-center cursor-pointer">
            <img src={Logo2} width={100} alt="logo" className="mr-1 my-2.5" />
          </div>
        </Link>
        <div className={`menu ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <RxHamburgerMenu className="hamburger" />
        </div>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink to="/" onClick={toggleMenu}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/regulament" onClick={toggleMenu}>
            Regulament
          </NavLink>
        </li>
        <li>
          <NavLink to="/jurizare" onClick={toggleMenu}>
          Jurizare
          </NavLink>
        </li>
        <li>
          <NavLink to="/premii" onClick={toggleMenu}>
          Premii
          </NavLink>
        </li>
        <li>
          <NavLink to="/taxa" onClick={toggleMenu}>
          Taxa
          </NavLink>
        </li>
        <li>
          <NavLink to="/inscrieri-temp" onClick={toggleMenu}>
          Inscrieri
          </NavLink>
        </li>
        <li>
        <Toggle isChecked={theme === 'dark'} handleChange={toggleTheme} />
        </li>
       </ul>
    </nav>
  );
};

export default Navbar;
