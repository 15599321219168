import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import { FaArrowCircleRight } from "react-icons/fa";
import anulat from "../assets/anulat.jpeg";


export default function Home() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="anulat">
      <img src={anulat} className="w-full" alt="" />
    </div>

    <div className="max-w-6xl mx-auto mt-20">

<p className="text-center text-[20px] font-semibold mx-6 mb-10">
                
SIA Dance Cup își propune să ofere tuturor iubitorilor de dans, sub toate formele lui, ocazia de a străluci pe scenă și de a trăi emoțiile unei competiții profesioniste și totodată de a promova tinerele talente.
                </p>

                <p className='divider'/>
                <p className="text-center text-[20px] font-semibold mx-6 mt-10 mb-10">
                Categorii Concurs
                </p>
      
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
            Balet Clasic
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
            Lyrical
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Dans Contemporan
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Dans de Caracter
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Folclor National
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Folclor International
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Etno Stilizat
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Jazz
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Acro Dance
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Gimnastica Aerobica
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Gimnastica Ritmica
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Open
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Cabaret
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Musical
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Dans Sportiv
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Latino-American
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Dans Tematic
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Fantezie Coregrafica
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Show Dance
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Mtv Commercial
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Dans Modern
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Majorete
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Hip-Hop
        </div>
      </Grid>
      <Grid item xs={6} md={3}>
        <div className="StyledCard1">
        Street Dance
        </div>
      </Grid>
    </Grid>
    <div className=" mt-10">
  <p className='divider' />
  <p className=" text-[20px] font-semibold mx-6 mt-10 mb-10">
    Calendarul Competiției
  </p>
  <div className="flex items-center text-[18px] font-semibold mx-6 mt-2">
  <FaArrowCircleRight className="mr-3" />
  1 Septembrie - 25 Octombrie
</div>
  <p className="flex items-center  text-[16px] font-semibold ml-14">
    Perioada de înscriere
  </p>
  <div className="flex items-center text-[18px] font-semibold mx-6 mt-6">
  <FaArrowCircleRight className="mr-3" />
  28 Octombrie
</div>
  <p className="flex items-center  text-[16px] font-semibold ml-14">
  Trimiterea către participanți a desfășurătorului competiției
  </p>
  <div className="flex items-center text-[18px] font-semibold mx-6 mt-6">
  <FaArrowCircleRight className="mr-3" />
  29 - 31  Octombrie
</div>
  <p className="flex items-center  text-[16px] font-semibold ml-14">
  Perioada de modificări ale desfășurătorului
  </p>
  <div className="flex items-center text-[18px] font-semibold mx-6 mt-6">
  <FaArrowCircleRight className="mr-3" />
  2 Noiembrie
</div>
  <p className="flex items-center  text-[16px] font-semibold ml-14">
  Trimiterea desfașurătorului final
  </p>
  <div className="flex items-center text-[18px] font-semibold mx-6 mt-6">
  <FaArrowCircleRight className="mr-3" />
  9 Noiembrie
</div>
  <p className="flex items-center  text-[16px] font-semibold ml-14">
  Desfășurarea competiției SIA Dance Cup
  </p>
</div>
  <p className='divider' />
  <p className="text-[16px] font-semibold mx-6">
  *Organizatorii își rezervă dreptul de a închide procesul de înregistrare mai devreme, înainte de teremenul limită de 27 Octombrie 2024, dacă numărul concurenților pentru fiecare categorie a atins capacitatea maximă.
  </p>
    </div>
    </div>
  );
};
