import React, {useEffect } from "react";
import Grid from "@mui/material/Grid";

export default function Taxa() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="max-w-6xl mx-auto mt-20">
    <p className="text-left text-[20px] font-semibold mx-6 mb-1">
    Taxă SIA Dance Cup 9 Noiembrie 2024
    </p>
  <p className='divider'/>
    <div className="text-center mt-10">
  <p className="text-left text-[17px] font-semibold mx-6 mt-10">
  SOLO – 140 LEI - dansator / reprezentație </p>
  <p className="text-left text-[17px] font-semibold mx-6 mt-5">
  DUO / PAS DE DEUX – 100 LEI - dansator / reprezentație </p>
  <p className="text-left text-[17px] font-semibold mx-6 mt-5">
  TRIO – 100 LEI - dansator / reprezentație</p>
  <p className="text-left text-[17px] font-semibold mx-6 mt-5">
  QUARTET – 100 LEI - dansator / reprezentație</p>
  <p className="text-left text-[17px] font-semibold mx-6 mt-5">
  GRUP – 90 LEI - dansator / reprezentație (5 - 11 dansatori)</p>
  <p className="text-left text-[17px] font-semibold mx-6 mt-5 mb-10">
  FORMATIE – 90 LEI - dansator / reprezentație (12+ dansatori )</p>
</div>
  <p className='divider'/>
  
  <p className="text-left text-[17px] font-semibold mx-6 mt-5">
  Înscrierea în cadrul competiției va fi validă numai în momentul în care plata va fi efectuată, iar dovada va fi trimsă către organizatori. </p>
  <p className="text-left text-[17px] font-semibold mx-6 mt-5">
  Taxa de înscriere se achită prin transfer bancar în contul ART PERFORMANCE SRL, conform tarifelor secțiunii / secțiunilor la care participă concurentul / concurenții și a numărului de reprezentații.</p>
  <p className="text-left text-[17px] font-semibold mx-6 mt-5">
  Tarifele menționate se vor achita per concurent / grup / formație.</p>
  <p className="text-left text-[17px] font-semibold mx-6 mt-5">
  În detaliile plății trebuiesc specificate numele școlii, numărul de participanți – taxă SIA. 
  <br/>În cazul participanțiilor independenți se va specifica numele dansatorului / dansatorilor, numărul de participanți – taxă SIA.</p>
  <p className="text-left text-[17px] font-semibold mx-6 mt-5 mb-10">
  !!! Taxa de înscriere este nerambursabilă !!!</p>
   
    </div>
  );
};
