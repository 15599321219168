import React, { createContext, useContext, useState, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDark, setIsDark] = useLocalStorage("isDark"); // Preferința inițială se extrage din localStorage
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    // Setăm tema în funcție de preferința inițială
    setTheme(isDark ? 'dark' : 'light');
  }, [isDark]);

  const toggleTheme = () => {
    // Invertim tema și actualizăm preferința inițială în localStorage
    setIsDark(!isDark);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
