import React, {useEffect } from "react";



export default function Regulament() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
  <div className="max-w-6xl mx-auto mt-20">
  <p className="text-left text-[20px] font-semibold mx-6 mb-1">
    Regulament SIA Dance Cup 9 Noiembrie 2024
  </p>
  <p className='divider'/>
  <p className="text-left text-[18px] font-semibold mx-6 mt-10 mb-10">
  Concursul Național SIA Dance Cup își propune să ofere tuturor iubitorilor de dans, sub toate formele lui, ocazia de a străluci pe scenă și de a trăi emoțiile unei competiții profesionesite și totodată de a promova tinerele talente. 
  <br/>SIA Dance Cup se adresează tuturor școlillor de dans de stat sau private, liceelor de profil, grădinițelor, centrelor de dans, dansatorilor independenți (individual / grup / formație).
  </p>
  <p className='divider'/>
  <p className="text-left text-[20px] font-semibold mx-6 mt-10 mb-5">
  I. SECȚIUNI DE CONCURS
  </p>
  <div className="max-w-6xl mx-auto">
  <ul className="text-left text-[18px] font-semibold mx-6 mb-10">
    <li className="mb-2">Solo – 1 dansator</li>
    <li className="mb-2">Duo / Pas de deux – 2 dansatori</li>
    <li className="mb-2">Trio – 3 dansatori</li>
    <li className="mb-2">Quartet – 4 dansatori</li>
    <li className="mb-2">Grup – 5 - 11 dansatori</li>
    <li>Formație – 12+ dansatori</li>
  </ul>
</div>
  <p className='divider'/>
  <p className="text-left text-[20px] font-semibold mx-6 mt-10 mb-5">
  II. NIVEL DE PREGĂTIRE
  </p>
  <div className="max-w-6xl mx-auto">
  <ul className="text-left text-[18px] font-semibold mx-6 mb-10">
    <li className="mb-2">HOBBY – maxim 4 ore de prgătire pe săptămână</li>
    <li className="mb-4">PRO – mai mult de 4 ore de pregătire pe săptămână</li>
    <li className="text-[16px] font-semibold mt-2">*Organizatorii își rezervă dreptul de a reîncadra momentul la nivelul de pregătire adecvat în cazul în care este observat un nivel de pregătire superior sau inferior față de cel încadrat inițial.</li>
  </ul>
</div>
  <p className='divider'/>
  <p className="text-left text-[20px] font-semibold mx-6 mt-10 mb-5">
  III. CATEGORII DE VÂRSTĂ (VÂRSTA ÎMPLINITĂ LA 1 IANUARIE 2024)
  </p>
  <div className="max-w-6xl mx-auto">
  <ul className="text-left text-[18px] font-semibold mx-6 mb-10">
    <li className="mb-2">Baby – 3 - 4 ani</li>
    <li className="mb-2">Mini – 4 - 6 ani</li>
    <li className="mb-2">Children – 7 - 9 ani</li>
    <li className="mb-2">Juniors – 10 - 12 ani</li>
    <li className="mb-2">Juniors Maxi – 13 - 15 ani</li>
    <li className="mb-2">Seniors – 16+ ani</li>
    <li className="mb-5">Adults – 20+ ani</li>
    <li className="text-[16px] font-semibold mt-2">*Organizatorii își rezervă dreptul de a solicita dovada vârstei copilului / copiilor în cazul exsitenței unei suspiciuni în ceea ce privește încadrarea corectă în categoria de vârstă!
<br/>**În grup / formație sunt admiși și copii cu vârste mai mici sau mai mari, dar media aritmetică a vârstelor membrilor unui grup / formție să nu treacă de categoria de vârstă la care sunt încadrați (ex: 11+10+9+8+7 = 45:5 = 9 se încadrează la categoria Children 7 – 9 ani)</li>
  </ul>
</div>
  <p className='divider'/>
  <p className="text-left text-[20px] font-semibold mx-6 mt-10 mb-5">
  IV. DISCIPLINE DE CONCURS :
  </p>
  <div className="max-w-6xl mx-auto">
  <ul className="text-left text-[18px] font-semibold mx-6 mb-10">
    <li className="mb-2">Balet clasic</li>
    <li className="mb-2">Lyrical</li>
    <li className="mb-2">Dans contemporan</li>
    <li className="mb-2">Dans de caracter</li>
    <li className="mb-2">Folclor național</li>
    <li className="mb-2">Folclor internațional</li>
    <li className="mb-2">Etno stilizat</li>
    <li className="mb-2">Jazz</li>
    <li className="mb-2">Acro dance</li>
    <li className="mb-2">Gimnastică aerobică</li>
    <li className="mb-2">Gimnastică ritmică</li>
    <li className="mb-2">Open</li>
    <li className="mb-2">Cabaret</li>
    <li className="mb-2">Musical</li>
    <li className="mb-2">Dans sportiv</li>
    <li className="mb-2">Latino-american</li>
    <li className="mb-2">Dans tematic</li>
    <li className="mb-2">Fantezie coregrafică</li>
    <li className="mb-2">Show dance</li>
    <li className="mb-2">Mtv commercial</li>
    <li className="mb-2">Dans modern</li>
    <li className="mb-2">Majorete</li>
    <li className="mb-2">Hip-hop</li>
    <li className="mb-5">Street dance</li>
    <li className="text-[16px] font-semibold mt-2">*Pentru disciplina balet calsic este permisă utilizarea de pointes doar copiilor care au împlinit vârsta de 12 ani! Aceasta va fi jurizată separat!</li>
  </ul>
</div>
  <p className='divider'/>
  <p className="text-left text-[20px] font-semibold mx-6 mt-10 mb-5">
  V. DURATA MOMENTULUI COREGRAFIC :
  </p>
  <div className="max-w-6xl mx-auto">
  <ul className="text-left text-[18px] font-semibold mx-6 mb-10">
    <li className="mb-2">Solo – 1:00 - 2:30 minute</li>
    <li className="mb-2">Duo / Trio / Quartet – 1:00 - 3:00 minute</li>
    <li className="mb-2">Grup / Formație – 1:00 - 4:30 minute</li>
    <li className="mb-5">Pas de deux (adagio & coda) – 6:00 minute</li>
    <li className="text-[16px] font-semibold mt-2">*În cazul în care, în cadrul momentului artistic dansatorii au nevoie de recuzită, aceștia sunt responsabili de a-și aduce obiectele necesare, precum și de a avea gijă de acestea pe toată durata concursului.</li>
  </ul>
</div>
  <p className='divider'/>
  <p className="text-left text-[20px] font-semibold mx-6 mt-10 mb-5">
  VI. ÎNSCRIERE ȘI TAXE :
  </p>
  <div className="max-w-6xl mx-auto">
  <ul className="text-left text-[18px] font-semibold mx-6 mb-10">
    <li className="mb-5">Înscrierea în cadrul competiției se face prin completarea integrală a formularului pus la dispoziție pe site.
Muzica pentru fiecare dintre momentele coregrafice se va încărca în prezentul formular în format mp3.
Formularul de înscriere este valid doar dacă sunt completate toate câmpurile obligatorii și sunt încărcate toate documentele solicitate (piesa / piesele denumite corect conținând numele dansatorului / formației precum și numele momentului, ex : Alesia Ion - Paquita).
Înscrierea în cadrul competiției va fi validă numai în momentul în care plata va fi efectuată, iar dovada trimsă către organizatori.</li>
    <li className="mb-2">SOLO – 140 LEI - dansator / reprezentație</li>
    <li className="mb-2">DUO / PAS DE DEUX – 100 LEI - dansator / reprezentație</li>
    <li className="mb-2">TRIO – 100 LEI - dansator / reprezentație</li>
    <li className="mb-2">QUARTET – 100 LEI - dansator / reprezentație</li>
    <li className="mb-2">GRUP – 90 LEI - dansator / reprezentație (5 - 11 dansatori)</li>
    <li className="mb-5">FORMATIE – 90 LEI - dansator / reprezentație (12+ dansatori)</li>
    <li className="mb-2">Tarifele menționate se vor achita per concurent / grup / formație.
Taxa de înscriere se achită prin transfer bancar în contul ART PERFORMANCE SRL, conform tarifelor secțiunii / secțiunilor la care participă concurentul / concurenții și a numărului de reprezentații.
<br/>Tarifele menționate se vor achita per concurent / grup / formație.
<br/>În detaliile plății trebuiesc specificate numele școlii, numărul de participanți – taxă SIA. 
<br/>În cazul participanțiilor independenți se va specifica numele dansatorului / dansatorilor, numărul de participanți – taxă SIA.
<br/>!!! Taxa de înscriere este nerambursabilă !!!</li>
  </ul>
</div>
  <p className='divider'/>
  <p className="text-left text-[20px] font-semibold mx-6 mt-10 mb-5">
  VII. SISTEM DE JURIZARE :
  </p>
  <div className="max-w-6xl mx-auto">
  <ul className="text-left text-[18px] font-semibold mx-6 mb-10">
    <li className="mb-5">Juriul este format din 4 persoane. Punctajul maxim este de 120 puncte, fiecărui membru al juriului îi revin 30 de puncte prin care vor evalua următoarele criterii:</li>
    <li className="mb-2">5-10 puncte / jurat – Nivel Tehnic / Excecuție</li>
    <li className="mb-2">5-10 puncte / jurat - Interpretarea artistică</li>
    <li className="mb-5">5-10 puncte / jurat- Orientarea scenică</li>
    <li className="mb-2">Punctaj maixm / criteriu de jurizare = 10 p</li>
    <li className="mb-2">Punctaj maxim / jurat = 30 p</li>
    <li className="mb-5">Punctaj maxim general = 120 p</li>
    <li className="mb-2">LOCUL I 100-120 puncte</li>
    <li className="mb-2">LOCUL II 80-99 puncte</li>
    <li className="mb-5">LOCUL III sub 79 puncte</li>
    <li className="mb-2">! Daca sunt mai puțin de 3 sau 3 participanți pe aceeaşi secțiune se acordă locul în ordinea punctajului</li>
    <li className="mb-5">!! La mai mult de 3 participanți pe aceeași secțiune se vor acorda locurile conform baremului.</li>
    <li className="mb-1">1. Nivelul Tehnic / Executie:</li>
    <li className="mb-5">Se punctează dificultatea și complexitatea elementelor alese, acuratețe în executarea mișcărilor, alegerea mișcărilor în conformitate cu vârsta.</li>
    <li className="mb-1">2. Interpretarea artistică:</li>
    <li className="mb-5">Se punctează muzicalitatea, expresivitatea, sincronul și originalitatea momentului coregrafic.</li>
    <li className="mb-1">3. Orientarea scenică:</li>
    <li className="mb-5">Se punctează poziționarea dansatorilor pe scenă, utilizarea scenei, aspectul costumelor.</li>
    <li className="mb-2">! Participanții vor fi depunctați în următoarele situații :</li>
    <li className="mb-2">1. Înscrierea momentului la o disciplină necorespunzătoare, determină dreptul juraților de a-l depuncta și a-l muta la secțiunea potrivită.</li>
    <li className="mb-2">2. Folosirea unor expresii vulgare în cadrul colajelor muzicale, precum și folosirea unor gesturi sau semne vulgare.</li>
    <li className="mb-2">!! Pentru secțiunea solo jurizarea se va face separat pentru fete și băieți, cât și pentru variațiile de balet cu sau fără pointe.</li>
    <li className="mb-5">!!! Hotărârile juruiului și punctajele sunt definitive și incontestabile.</li>
  </ul>
</div>
  <p className='divider'/>
  <p className="text-left text-[20px] font-semibold mx-6 mt-10 mb-5">
  VIII. ACORD DE FOLOSIRE A IMAGINII
  </p>
  <div className="max-w-6xl mx-auto">
  <ul className="text-left text-[18px] font-semibold mx-6 mb-10">
    <li className="mb-5">Organizatorii își rezervă dreptul de a realiza fotografii, înregistrări video și înregistrări audio în timpul competiției care devin proprietatea SIA Dance Cup, precum și de dreptul de a salva și / sau utiliza aceste materiale în scopuri de marketing și publicitate.
<br/>Prin înscrierea în concurs sunteți de acord cu fotografierea, respectiv înregistrarea audio-video și utilizarea înregistrarilor în toate acțiunile referitoare la SIA Dance Cup și declarați ca nu aveți și nu veți avea nici o pretenție față de aceste apariții.</li>
  </ul>
</div>
  <p className='divider'/>
  <p className="text-left text-[20px] font-semibold mx-6 mt-10 mb-5">
  IX. CONSIMȚĂMÂNT CU PRIVIRE LA PRELUCRAREA DATELOR CU CARACTER PERSONAL
  </p>
  <div className="max-w-6xl mx-auto">
  <ul className="text-left text-[18px] font-semibold mx-6 mb-10">
    <li className="mb-5">Prin înscrierea în concurs vă dați acordul cu privire la prelucrarea de către SIA Dance Cup a datelor dvs. cu caracter personal. Consimțământul se referă la toate activitățile de prelucrare a datelor cu caracter personal ale dvs. de către operatorul menționat, cum ar fi, dar fără a se limita la colectarea, înregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea etc. respectivelor date cu caracter personal. Scopurile în care pot fi utilizate datele cu caracter personal sunt cele care privesc organizarea și desfășurarea SIA Dance Cup și a activităților conexe.
<br/>Prin înscrierea la concurs sunteți de acord cu Regulamentul concursului SIA Dance Cup.
<br/>În acest sens, SIA Dance Cup se obligă să asigure constant un nivel optim de protecție a datelor cu caracter personal, precum și măsuri de securitate specifice.</li>
  </ul>
</div>
  <p className='divider'/>
  <p className="text-left text-[20px] font-semibold mx-6 mt-10 mb-5">
  X. REGULAMENT DE ORDINE INTERIOARĂ
  </p>
  <div className="max-w-6xl mx-auto">
  <ul className="text-left text-[18px] font-semibold mx-6 mb-10">
    <li className="mb-2">1. Pentru o bună colaborare în ziua competiției, directorii / coregrafii respectiv profesorii trebuie să transmită prezentul Regulament elevilor și însoțitorilor acestora.</li>
    <li className="mb-2">2. SIA Dance Cup nu dispune de asigurare medicală pentru concurenţii săi. Concurenţii şi părinţii înţeleg că riscul sau prejudiciul posibil ce poate rezulta din activităţile specifice unei competiţii de dans este semnificativ, iar SIA Dance Cup sau oricare dintre angajaţii sau colaboratorii săi ori proprietarii spaţiilor puse la dispoziţie concurenţilor nu-şi asumă niciun fel de responsabilitate pentru orice prejudiciu, invaliditate, deces, sau pierderea, deteriorarea bunurilor personale, care apar din neglijența concurentului, totodată, SIA Dance Cup nu este responsabil sau răspunzător pentru niciun bun pierdut sau furat.</li>
    <li className="mb-2">3. Părinţilor nu le va fi permis accesul în culise decât în cazul excepțional în care concurenții au schimbări rapide între dansuri și coregraful nu se poate descurca.</li>
    <li className="mb-2">4. Intrarea în culise se va face cu 5 numere înainte de evoluția scenică, conform desfășurătorului afișat după validarea înscrierilor, și trebuie să vă asigurati că aveți tot ce este necesar pentru evoluția scenică.</li>
    <li className="mb-2">5. Este obligatorie, pentru toate persoanele care vor avea acces în instituție, respectarea normelor de disciplină competițională, cu un comportament civilizat, care să nu aducă prejudicii fizice sau morale oricărui participant la eveniment.</li>
    <li className="mb-2">6. În timpul desfășurării actului artistic, se va păstra liniștea, atât în sala de concurs, cât și în culise.</li>
    <li className="mb-2">7. În toate spațiile în care se desfășoară competiția se va păstra curățenia.</li>
    <li className="mb-2">8. Consumul de alimente și băuturi este strict interzis în sala de concurs și în culise.</li>
    <li className="mb-2">9. Cabinele de schimb (zona destinată schimbării vestimentației) trebuiesc folosite de către toți concurenții. Drept urmare trebuiesc eliberate după schimbarea costumelor.</li>
    <li className="mb-2">10. Este interzisă blocarea căilor de acces.</li>
    <li className="mb-2">11. Este interzisă participarea în concurs cu obiecte care pot deteriora suprafața de dans (ex: confetti, obiecte ascuțite etc). Concurenții care folosesc obiecte ajutătoare vor solicita în prealabil (cu cel puțin 2 zile înainte de ziua concursului) acordul organizatorului.</li>
    <li className="mb-2">12. Orice persoană care produce daune locației, în interior sau exterior, din neglijență sau din abuz, își asumă întreaga responsabilitate financiară pentru recuperarea daunei.</li>
    <li className="mb-2">13. Accesul în sala de concurs este liber pe toată durata desfășurării concursului în limita locurilor disponibile.</li>
    <li className="mb-2">14. Prin prezența în cadrul concursului SIA Dance Cup, concurenții, însoțitorii și persoanele din public își dau acordul pentru apariția în fotografiile și înregistrările video realizate pe parcursul competiției și folosirea ulterioară a acestora de către organizator pe canalele oficiale de social media.</li>
  </ul>
</div>
</div>
  );
};
