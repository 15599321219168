import { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { db } from "../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    telefon: "",
    password: "",
  });
  const { name, email, telefon, password } = formData;
  const navigate = useNavigate();

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();
  
    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
  
      updateProfile(auth.currentUser, {
        displayName: name,
      });
  
      const user = userCredential.user;
  
      // Construim un obiect cu doar câmpurile necesare
      const formDataToSend = {
        name: name,
        email: email,
        telefon: telefon,
        timestamp: serverTimestamp(),
      };
  
      // Stocăm datele în Firestore
      await setDoc(doc(db, "users", user.uid), formDataToSend);
  
      toast.success("Înregistrare cu succes");
      navigate("/profile");
    } catch (error) {
      toast.error("Ceva nu a mers bine");
    }
  }

  return (
    <section>
      <h1 className="text-2xl text-center mt-6 font-bold">Crează Cont</h1>
      <div className="flex justify-center flex-wrap items-center px-6 py-12 max-w-6xl mx-auto">
        <div className="w-full md:w-[67%] lg:w-[40%] lg:ml-2">
          <form onSubmit={onSubmit}>
        <div className="w-full mb-6">
          <p className="text-lg font-semibold">Școală / Club</p>
          <input 
          type="text"
           id="name"
           onChange={onChange}
           required 
           placeholder="..."
         className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
        </div>

        <div className="w-full mb-6">
          <p className="text-lg font-semibold">Email</p>
          <input 
                type="email"
                id="email"
                onChange={onChange}
                required
                placeholder=".@."
         className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
        </div>

            <div className="w-full mb-6">
              <p className="text-lg font-semibold">Telefon</p>
              <input
                type="text"
                id="telefon"
                onChange={onChange}
                required
                placeholder="072..."
                className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
            </div>

            <div className="relative mb-6">
              <p className="text-lg font-semibold">Parolă</p>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={onChange}
                placeholder="min 6 caractere"
                className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
                {showPassword ? (
                <AiFillEyeInvisible
                  className="absolute right-3 top-10 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : (
                <AiFillEye
                  className="absolute right-3 top-10 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )}
            </div>

            <div className="flex justify-between whitespace-nowrap">
              <p className="mb-6">
                <Link
                  to="/sign-in"
                 >
                  Sign-in
                </Link>
              </p>
              <p>
                <Link
                  to="/forgot-password"
                 >
                  Reseteaza parola
                </Link>
              </p>
            </div>
          <p className='divider'/>
            <button
                className="w-full transition duration-150 ease-in-out"
                type="submit"
            >
              Înregistrare
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
