import React from 'react';
import './table.css'; 

export default function ListingHead({}) {
  return (
    <div>
      <table className="table mb-3">
        <thead>
          <tr>
            <th>Concurent</th>
            <th>Moment</th>
            <th className="th2">Taxa</th>
            <th className="th2">Mp3</th>
            <th className="th2">E / S</th>
          </tr>
        </thead>
      </table>
    <p className='divider1' />
    </div>
  );
}
