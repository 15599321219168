import { useState, useEffect } from "react";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { addDoc, collection, serverTimestamp,
  doc,
  getDoc, } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

export default function CreateListing() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    images: {},
    type: "rent",
    name: "",
    email: "",
    telefon: "",
    disciplina: "Balet clasic",
    varsta: "baby 3 - 4",
    sectiune: "Solo",
    pregatire: "Hobby",
    moment: "",
    concurent: "",
    coregraf: "",
    participanti: "1",
    durata: "",
    intrare: "Din poza",
    agreeRegulament: false 
  });
  const {
    images,
    type,
    name,
    email,
    telefon,
    disciplina,
    varsta,
    sectiune,
    pregatire,
    moment,
    concurent,
    coregraf,
    participanti,
    durata,
    intrare,
  } = formData;

  useEffect(() => {
    if (auth.currentUser) {
      fetchUserListings();
    }
  }, [auth.currentUser]);

  async function fetchUserListings() {
    try {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setFormData((formData) => ({
          ...formData,
          name: userData.name,
          telefon: userData.telefon,
          email: userData.email,
        }));
      }
    } catch (error) {
      console.error("Error fetching user listings:", error);
    }
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
  
    if (name === "sectiune") {
      switch (value) {
        case "Solo":
          setFormData((prevFormData) => ({
            ...prevFormData,
            sectiune: value,
            participanti: 1,
          }));
          break;
        case "Duo":
        case "Pas de deux":
          setFormData((prevFormData) => ({
            ...prevFormData,
            sectiune: value,
            participanti: 2,
          }));
          break;
        case "Trio":
          setFormData((prevFormData) => ({
            ...prevFormData,
            sectiune: value,
            participanti: 3,
          }));
          break;
        case "Quartet":
          setFormData((prevFormData) => ({
            ...prevFormData,
            sectiune: value,
            participanti: 4,
          }));
          break;
        case "Grup 5 - 11 dansatori":
          setFormData((prevFormData) => ({
            ...prevFormData,
            sectiune: value,
            participanti: "",
          }));
          break;
        case "Formație 12+ dansatori":
          setFormData((prevFormData) => ({
            ...prevFormData,
            sectiune: value,
            participanti: "",
          }));
          break;
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  function onChange(e) {
    let boolean = null;
    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }
    // Files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        images: e.target.files,
      }));
    }
    // Text/Boolean/Number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  }
  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (images.length > 2) {
      setLoading(false);
      toast.error("max 1");
      return;
    }

    async function storeImage(image) {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const filename = `${auth.currentUser.displayName}-${image.name}`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    }

    const imageUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch((error) => {
      setLoading(false);
      toast.error("mp3 not uploaded");
      return;
    });

    const formDataCopy = {
      ...formData,
      imageUrls,
      timestamp: serverTimestamp(),
      userRef: auth.currentUser.uid,
    };
    delete formDataCopy.images;
    const docRef = await addDoc(collection(db, "listings"), formDataCopy);
    setLoading(false);
    toast.success("Moment adaugat");
    navigate(`/profile`);
  }

  if (loading) {
    return <Spinner />;
  }
  return (
    
    <section>
      <h1 className="text-2xl text-center mt-6 font-bold">Adauga Moment</h1>

      <form onSubmit={onSubmit}>
      <div className="flex justify-center flex-wrap items-center px-6 py-12 max-w-6xl mx-auto">
     
        <div className="w-full md:w-[67%] lg:w-[35%]">

      <div className="w-full mb-6">
          <p className="text-lg font-semibold">Instructor / Coregraf</p>
          <input type="text" name="coregraf" onChange={handleChange} required 
          className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
      </div>
       
            <div className="w-full mb-6 relative">
           <p className="text-lg font-semibold">Disciplina</p>
           <select name="disciplina" value={disciplina} onChange={handleChange} required
            className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer">
            <option value="Balet clasic">Balet clasic</option>
           <option value="Balet neoclasic">Balet neoclasic</option>
           <option value="Lyrical">Lyrical</option>
           <option value="Dans contemporan">Dans contemporan</option>
           <option value="Dans de caracter">Dans de caracter</option>
           <option value="Folclor național">Folclor național</option>
           <option value="Folclor internațional">Folclor internațional</option>
           <option value="Etno stilizat">Etno stilizat</option>
           <option value="Jazz">Jazz</option>
           <option value="Acro dance">Acro dance</option>
           <option value="Gimnastică aerobică">Gimnastică aerobică</option>
           <option value="Gimnastică ritmică">Gimnastică ritmică</option>
           <option value="Open">Open</option>
           <option value="Cabaret">Cabaret</option>
           <option value="Musical">Musical</option>
           <option value="Dans sportiv">Dans sportiv</option>
           <option value="Latino-american">Latino-american</option>
           <option value="Dans tematic">Dans tematic</option>
           <option value="Fantezie coregrafică">Fantezie coregrafică</option>
           <option value="Show dance">Show dance</option>
           <option value="Mtv commercial">Mtv commercial</option>
           <option value="Dans modern">Dans modern</option>
           <option value="Majorete">Majorete</option>
           <option value="Hip-hop">Hip-hop</option>
           <option value="Street dance">Street dance</option>
           </select>
           <FaChevronDown 
           className="absolute right-0 top-0 mt-11 mr-4 text-gray-500" />
          </div>

<div className="w-full mb-6 relative">
  <p className="text-lg font-semibold">Categorie varsta</p>
  <select name="varsta" value={formData.varsta} onChange={handleChange} required
   className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer">
  <option value="Baby 3 - 4 ani">Baby 3 - 4 ani</option>
  <option value="Mini 4 - 6 ani">Mini 4 - 6 ani</option>
  <option value="Children 7 - 9 ani">Children 7 - 9 ani</option>
  <option value="Juniors 10 - 12 ani">Juniors 10 - 12 ani</option>
  <option value="Juniors Maxi 13 - 15 ani">Juniors Maxi 13 - 15 ani</option>
  <option value="Seniors 16+ ani">Seniors 16+ ani</option>
  <option value="Adults 20+ ani">Adults 20+ ani</option>
</select>
  <FaChevronDown 
  className="absolute right-0 top-0 mt-11 mr-4 text-gray-500" />
</div>

<div className="w-full mb-6 relative">
  <p className="text-lg font-semibold">Sectiune</p>
<select name="sectiune" value={formData.sectiune} onChange={handleChange} required
 className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer">
  <option value="Solo">Solo</option>
  <option value="Duo">Duo</option>
  <option value="Pas de deux">Pas de deux</option>
  <option value="Trio">Trio</option>
  <option value="Quartet">Quartet</option>
  <option value="Grup 5 - 11 dansatori">Grup 5 - 11 dansatori</option>
  <option value="Formație 12+ dansatori">Formație 12+ dansatori</option>
</select>
  <FaChevronDown 
  className="absolute right-0 top-0 mt-11 mr-4 text-gray-500" />
</div>

        <div className="w-full mb-6">
          <p className="text-lg font-semibold">Numar participanti</p>
          <input type="text" name="participanti" 
    value={formData.participanti} onChange={handleChange} required 
         className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
        </div>
        


        </div>



        <div className="w-full md:w-[67%] lg:w-[35%] lg:ml-10">
       

<div className="w-full mb-6">
  <p className="text-lg font-semibold">Nume Concurent / Grup / Formatie</p>
  <input type="text" name="concurent" onChange={handleChange} required 
 className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
</div>
        
        <div className="w-full mb-6 relative">
       <p className="text-lg font-semibold">Nivel de pregatire</p>
      <select
        name="pregatire"
        value={formData.pregatire}
       onChange={handleChange}
       required
      className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"
         >
      <option value="Hobby">Hobby</option>
        <option value="Pro">Pro</option>
        </select>
        <FaChevronDown 
       className="absolute right-0 top-0 mt-11 mr-4 text-gray-500" />
       </div>
        

       <div className="w-full mb-6">
          <p className="text-lg font-semibold">Denumire moment</p>
          <input type="text" name="moment" onChange={handleChange} required 
         className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
        </div>

        <div className="w-full mb-6">
          <p className="text-lg font-semibold">Durata momentului</p>
          <input type="text" name="durata" onChange={handleChange} required  
         className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
        </div>
        
        <div className="w-full mb-6 relative">
       <p className="text-lg font-semibold">Intrare</p>
          <select name="intrare" 
          value={formData.intrare} 
          onChange={handleChange} 
          required
      className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"
         >
         <option value="Din poza">Din poza</option>
            <option value="Pe muzica">Pe muzica</option>
        </select>
        <FaChevronDown 
       className="absolute right-0 top-0 mt-11 mr-4 text-gray-500" />
       </div>
      
        </div>
    <div className="flex-wrap items-center md:w-[67%] lg:w-[40%] px-6 mx-auto">

<div className="mb-5">
  <p className="text-lg font-semibold">Melodie mp3</p>
  <input
    type="file"
    id="images"
    onChange={onChange}
    accept=".mp3"
    required
    className="input w-full transition duration-150 ease-in-out cursor-pointer"/>
</div>

    <div className="flex justify-center">
          <input type="checkbox" name="agreeRegulament" checked={formData.agreeRegulament} onChange={handleChange} required  
         className="mt-1"
        />
         &nbsp; Sunt de acord cu regulamentul
        </div>

        <button
          type="submit"
          className="bg-transparent uppercase hover:bg-purple-900 transition duration-150 ease-in-out"
   >
          Adauga Moment
        </button>
        </div>

      </div>
    </form>
    </section>
  );
}
