import React, {useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";


export default function InscrieriTemp() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <section className="max-w-7xl mx-auto">
      <div className="fixed-bottom-background"></div> 
      
      {/* Popup */}
     
      <div className="inscrieri-temp absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white rounded-lg shadow-lg p-8 z-10 font-semibold">
      <div className="text-lg font-semibold">Perioada de inscrieri</div>
      <div className="text-lg font-semibold mt-3 mb-3">1 Septembrie - 25 Octombrie</div>
    </div>
    
<form >
      <div className="flex justify-center flex-wrap items-center px-6 py-12 max-w-6xl mx-auto">
     
        <div className="w-full md:w-[67%] lg:w-[35%]">

      <div className="w-full mb-6">
          <p className="text-lg font-semibold">Instructor / Coregraf</p>
          <input type="text" name="coregraf" 
          className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
      </div>
       
            <div className="w-full mb-6 relative">
           <p className="text-lg font-semibold">Disciplina</p>
           <select name="disciplina" 
            className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer">
            <option value="Balet clasic">Balet clasic</option>
           <option value="Balet neoclasic">Balet neoclasic</option>
           <option value="Lyrical">Lyrical</option>
           <option value="Dans contemporan">Dans contemporan</option>
           <option value="Dans de caracter">Dans de caracter</option>
           <option value="Folclor național">Folclor național</option>
           <option value="Folclor internațional">Folclor internațional</option>
           <option value="Etno stilizat">Etno stilizat</option>
           <option value="Jazz">Jazz</option>
           <option value="Acro dance">Acro dance</option>
           <option value="Gimnastică aerobică">Gimnastică aerobică</option>
           <option value="Gimnastică ritmică">Gimnastică ritmică</option>
           <option value="Open">Open</option>
           <option value="Cabaret">Cabaret</option>
           <option value="Musical">Musical</option>
           <option value="Dans sportiv">Dans sportiv</option>
           <option value="Latino-american">Latino-american</option>
           <option value="Dans tematic">Dans tematic</option>
           <option value="Fantezie coregrafică">Fantezie coregrafică</option>
           <option value="Show dance">Show dance</option>
           <option value="Mtv commercial">Mtv commercial</option>
           <option value="Dans modern">Dans modern</option>
           <option value="Majorete">Majorete</option>
           <option value="Hip-hop">Hip-hop</option>
           <option value="Street dance">Street dance</option>
           </select>
           <FaChevronDown 
           className="absolute right-0 top-0 mt-11 mr-4 text-gray-500" />
          </div>

<div className="w-full mb-6 relative">
  <p className="text-lg font-semibold">Categorie varsta</p>
  <select name="varsta" 
   className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer">
  <option value="Baby 3 - 4 ani">Baby 3 - 4 ani</option>
  <option value="Mini 4 - 6 ani">Mini 4 - 6 ani</option>
  <option value="Children 7 - 9 ani">Children 7 - 9 ani</option>
  <option value="Juniors 10 - 12 ani">Juniors 10 - 12 ani</option>
  <option value="Juniors Maxi 13 - 15 ani">Juniors Maxi 13 - 15 ani</option>
  <option value="Seniors 16+ ani">Seniors 16+ ani</option>
  <option value="Adults 20+ ani">Adults 20+ ani</option>
</select>
  <FaChevronDown 
  className="absolute right-0 top-0 mt-11 mr-4 text-gray-500" />
</div>

<div className="w-full mb-6 relative">
  <p className="text-lg font-semibold">Sectiune</p>
<select name="sectiune" 
 className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer">
  <option value="Solo">Solo</option>
  <option value="Duo">Duo</option>
  <option value="Pas de deux">Pas de deux</option>
  <option value="Trio">Trio</option>
  <option value="Quartet">Quartet</option>
  <option value="Grup 5 - 11 dansatori">Grup 5 - 11 dansatori</option>
  <option value="Formație 12+ dansatori">Formație 12+ dansatori</option>
</select>
  <FaChevronDown 
  className="absolute right-0 top-0 mt-11 mr-4 text-gray-500" />
</div>

        <div className="w-full mb-6">
          <p className="text-lg font-semibold">Numar participanti</p>
          <input type="text" name="participanti" 
         className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
        </div>
        


        </div>



        <div className="w-full md:w-[67%] lg:w-[35%] lg:ml-10">
       

<div className="w-full mb-6">
  <p className="text-lg font-semibold">Nume Concurent / Grup / Formatie</p>
  <input type="text" name="concurent" 
 className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
</div>
        
        <div className="w-full mb-6 relative">
       <p className="text-lg font-semibold">Nivel de pregatire</p>
      <select
        name="pregatire"
      className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"
         >
      <option value="Hobby">Hobby</option>
        <option value="Pro">Pro</option>
        </select>
        <FaChevronDown 
       className="absolute right-0 top-0 mt-11 mr-4 text-gray-500" />
       </div>
        

       <div className="w-full mb-6">
          <p className="text-lg font-semibold">Denumire moment</p>
          <input type="text" name="moment" 
         className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
        </div>

        <div className="w-full mb-6">
          <p className="text-lg font-semibold">Durata momentului</p>
          <input type="text" name="durata"
         className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"/>
        </div>
        
        <div className="w-full mb-6 relative">
       <p className="text-lg font-semibold">Intrare</p>
          <select name="intrare" 
      className="input w-full transition duration-150 ease-in-out mb-1 cursor-pointer"
         >
         <option value="Din poza">Din poza</option>
            <option value="Pe muzica">Pe muzica</option>
        </select>
        <FaChevronDown 
       className="absolute right-0 top-0 mt-11 mr-4 text-gray-500" />
       </div>
      
        </div>
    <div className="flex-wrap items-center md:w-[67%] lg:w-[40%] px-6 mx-auto">

<div className="mb-5">
  <p className="text-lg font-semibold">Melodie mp3</p>
  <input
    type="file"
    id="images"
    accept="audio/.*"
    required
    className="input w-full transition duration-150 ease-in-out cursor-pointer"/>
</div>

    <div className="flex justify-center">
          <input type="checkbox" name="agreeRegulament"  
         className="mt-1"
        />
         &nbsp; Sunt de acord cu regulamentul
        </div>

        <button
          type="submit"
          className="w-full transition duration-150 ease-in-out"
        >
          Adauga Moment
        </button>
        </div>

      </div>
    </form>
      </section>
    );
  }
  