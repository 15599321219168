import React, { useState } from "react";
import { FaPlay, FaPause, FaDownload } from "react-icons/fa";

export default function Test1({ listing }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.createRef();

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const downloadAudio = () => {
    const link = document.createElement('a');
    link.href = listing.imageUrls;
    link.download = 'audio.mp3';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
    
    <audio controls style={{  padding: '12px' }}>
      <source src={listing.imageUrls} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
      </div>
  );
}